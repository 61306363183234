import React, { useState } from "react";
import VerticalMenu from "../../../components/VerticalMenu";
import HorizontalMenu from "../../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import CodeContainer from "../../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../../images/favicon.ico";
import JsonContainer from "../../../components/JsonContainer";
import PageTitle from "../../../components/PageComponents/PageTitle";
import Paragraph from "../../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../../components/PageComponents/ParagraphTitle";
import {
  singleDataLibraryJson,
  singleDataLibraryResponseJson,
} from "../../../constants";
import SupportedMapTypes from "../../../components/SupportedMaptypes";
import {
  listStyle,
  listItems,
  codeFont,
  pageStyles,
  pageContent,
} from "../../../styleConstants";
import VersionSwitch from "../../../components/VersionSwitch";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={12} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Get details of a single dataset" />
          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />

          <Paragraph text="You can get the details of a single purchased data library dataset with the request below, by specifying the dataset id field." />
          {index === 0 ? (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v1/datalibraryorders/:id" />
          ) : (
            <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/datalibraryorders/:id" />
          )}
          <Paragraph text="You can also filter on data type. You can filter on the below data delivery products. To filter, you can include the following in the endpoint:" />
          <ul style={listStyle}>
            <li style={listItems}>
              <code style={codeFont}>fileDataType</code> : represents the data
              delivery product that is available. See below for details on the
              available data delivery products. If none is selected, only the
              baseFiles will be returned in the response.
            </li>
          </ul>
          <SupportedMapTypes />
          <ParagraphTitle text="Example Request" />
          {index === 0 ? (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v1/datalibraryorders/20220578syui" />
          ) : (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v2/datalibraryorders/20220578syui" />
          )}
          <JsonContainer text={singleDataLibraryJson} />
          <ParagraphTitle text="Example response" />
          <JsonContainer text={singleDataLibraryResponseJson} />
        </Container>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
